import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-shift-kpi" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "header px-5 pt-3 pb-2 mb-0 d-flex justify-content-between align-items-center" }, [_c("div", { staticClass: "d-flex align-items-center flex-wrap w-100" }, [_c("a-icon", { staticClass: "arrow-left pr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("h5", { staticClass: "pr-3 m-0 h5" }, [_vm._v("Add Shift KPI")])], 1)]), _c("div", { staticClass: "p-5" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "lg": 24, "xl": 8 } }, [_c("a-card", { style: { height: "100%" } }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { key: "shiftID", attrs: { "form-item": "", "data-source": _vm.listShiftTime, "source": "id", "source-label": "shiftDescription", "label": "Shift" }, on: { "change": function($event) {
      return _vm.storeValue("shiftID", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { key: "kpiDate", attrs: { "form-item": "", "format": "DD/MM/YYYY", "value": _vm.createForm.kpiDate, "rules": "required", "label": "Date" }, on: { "change": function($event) {
      return _vm.storeValue("kpiDate", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { key: "shiftLengthHours", attrs: { "form-item": "", "value": _vm.createForm.shiftLengthHours, "rules": "required", "label": "Shift Hour" }, on: { "change": function($event) {
      return _vm.storeValue("shiftLengthHours", $event);
    } } })], 1)], 1)], 1)], 1), _c("a-col", { attrs: { "lg": 24, "xl": 16 } }, [_c("a-card", [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "totalNumberofPeople", attrs: { "form-item": "", "rules": "required", "label": "People" }, on: { "change": function($event) {
      return _vm.storeValue("totalNumberofPeople", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "numberofCrews", attrs: { "form-item": "", "rules": "required", "label": "Crews" }, on: { "change": function($event) {
      return _vm.storeValue("numberofCrews", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("number-input", { key: "numberPeopleAbsent", attrs: { "form-item": "", "rules": "required", "label": "Absent" }, on: { "change": function($event) {
      return _vm.storeValue("numberPeopleAbsent", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("number-input", { key: "percentAbsent", attrs: { "form-item": "", "rules": "required", "suffix": "%", "value": _vm.createForm.percentAbsent, "disabled": "", "label": "% Absent" } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "totalDowntimeMins", attrs: { "form-item": "", "label": "D/Time Ch/Over", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("totalDowntimeMins", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "tonnesProduced", attrs: { "form-item": "", "label": "Tonnes Produced", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("tonnesProduced", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "tonnesPerHour", attrs: { "form-item": "", "value": _vm.createForm.tonnesPerHour, "rules": "required", "disabled": "", "label": "Tonnes Per Hr" } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "tonnesPerPerson", attrs: { "form-item": "", "value": _vm.createForm.tonnesPerPerson, "rules": "required", "disabled": "", "label": "Tonnes Per Person" } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("number-input", { key: "actualUnits", attrs: { "form-item": "", "label": "Unit Produced", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("actualUnits", $event);
    } } })], 1)], 1)], 1)], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("a-divider")], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("a-row", { attrs: { "justify": "end", "type": "flex" } }, [_c("a-button", { on: { "click": function($event) {
      return _vm.onBack();
    } } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary", "loading": _vm.isSubmitting }, on: { "click": function($event) {
      return handleSubmit(_vm.submit);
    } } }, [_vm._v("Create")])], 1)], 1)], 1)];
  } }]) })], 1)], 1);
};
var staticRenderFns$1 = [];
var CreateShiftKpi_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".create-shift-kpi .header[data-v-6361d53f]{background:white}.create-shift-kpi .header .arrow-left[data-v-6361d53f]{font-size:18px}\n")();
const __vue2_script$1 = {
  name: "CreateShiftKPI",
  inject: ["resourceProps", "area"],
  data() {
    const [shiftKpiProps, shiftTimeProps] = this.resourceProps;
    return {
      apirUrl: "#{VUE_APP_API_URL}#",
      isSubmitting: false,
      shiftKpiProps,
      shiftTimeProps,
      createForm: {},
      customQuery: "",
      itemsMenu: [
        {
          key: "report",
          title: "Report",
          path: ""
        },
        {
          key: "shift-kpi",
          title: "Shift KPI",
          path: "/line-performance/shift-kpi"
        },
        {
          key: "create-shift-kpi",
          title: "Add Shift KPI",
          path: "/line-performance/shift-kpi/create"
        }
      ]
    };
  },
  computed: {
    listShiftTime() {
      return this.shiftTimeProps.crud.getList() || [];
    }
  },
  watch: {
    "createForm.shiftID": function(newVal) {
      this.calculateShiftHour(newVal);
      this.calculateTonnesPerHour();
    },
    "createForm.shiftLengthHours": function() {
      this.calculateTonnesPerHour();
    },
    "createForm.tonnesProduced": function() {
      this.calculateTonnesPerHour();
      this.calculateTonnesPerPerson();
    },
    "createForm.totalNumberofPeople": function() {
      this.calculateTonnesPerPerson();
      this.calculatePercentAbsent();
    },
    "createForm.numberPeopleAbsent": function() {
      this.calculatePercentAbsent();
    }
  },
  async created() {
    this.storeValue("areaID", this.area.value);
    this.fetchListShift();
  },
  methods: {
    fetchListShift() {
      this.shiftTimeProps.crud.deleteFilter("IsInactive");
      this.shiftTimeProps.crud.deletePagination();
      this.customQuery = `areaID=${this.area.value}`;
      this.shiftTimeProps.crud.fetchList(false, void 0, true, this.customQuery, true);
    },
    onBack() {
      this.$router.push(`/line-performance/shift-kpi`);
    },
    async submit() {
      this.isSubmitting = true;
      try {
        await this.shiftKpiProps.crud.submitEntity("create").then((res) => {
          this.$router.push(`/line-performance/shift-kpi/${res.data.id}`);
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    storeValue(resourceKey, value) {
      this.createForm = {
        ...this.createForm,
        [resourceKey]: value
      };
      this.shiftKpiProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    calculateShiftHour(shiftId) {
      const selectedShift = this.listShiftTime ? this.listShiftTime.filter((val) => val.id === shiftId)[0] : null;
      if (selectedShift) {
        const startTime = this.$moment(selectedShift.startTime, "HH:mm");
        const finishTime = this.$moment(selectedShift.finishTime, "HH:mm");
        if (startTime.isSameOrAfter(finishTime)) {
          startTime.subtract(1, "days");
        }
        const shiftHours = finishTime.diff(startTime, "hours");
        this.storeValue("shiftLengthHours", shiftHours);
        const kpiDate = this.$moment(Date.now()).format("YYYY-MM-DD");
        this.storeValue("kpiDate", kpiDate);
      }
    },
    calculateTonnesPerPerson() {
      const tonnesPerPerson = this.createForm.tonnesProduced / this.createForm.totalNumberofPeople;
      if (tonnesPerPerson > 0 && isFinite(tonnesPerPerson))
        this.storeValue("tonnesPerPerson", tonnesPerPerson.toFixed(2));
      else
        this.storeValue("tonnesPerPerson", "");
    },
    calculateTonnesPerHour() {
      const tonnesPerHour = this.createForm.tonnesProduced / this.createForm.shiftLengthHours;
      if (tonnesPerHour > 0 && isFinite(tonnesPerHour))
        this.storeValue("tonnesPerHour", tonnesPerHour.toFixed(2));
      else
        this.storeValue("tonnesPerHour", "");
    },
    calculatePercentAbsent() {
      const percentAbsent = this.createForm.numberPeopleAbsent / (this.createForm.numberPeopleAbsent + this.createForm.totalNumberofPeople) * 100;
      if (!isNaN(percentAbsent) && isFinite(percentAbsent))
        this.storeValue("percentAbsent", percentAbsent.toFixed(2));
      else
        this.storeValue("percentAbsent", "");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6361d53f", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateShiftKpi = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-shift-kpi-container" }, [_c("resource", { attrs: { "name": ["line-performance.kpidata", "line-performance.common.shifts"], "api-url": _vm.apiUrl } }, [_c("create-shift-kpi")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateShiftKpi
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
